.Results {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
}

.Results > * {
    background-color: wheat;
    border-radius: 1em;
    padding: 2em 3em;
    border: ivory 1px solid;
    box-shadow: 1px 2px 3px darkgoldenrod;
    color: darkred;
    min-width: 50%;
}

.Results ul {
    list-style: none;
    margin: 2em 0;
}

.Results li {
    display: flex;
    border-bottom: 1px silver solid;
}

.Results li div {
    flex: 1;
    
}