.App {
  text-align: center;
  height: 100vh;
}

.App.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.App.loading p {
  background-color: rgba(250, 250, 100, 0.7);
  padding: 50px 100px;
  border-radius: 20px;
  box-shadow: 2px 4px 3px seashell;
  color: firebrick;
  font-weight: bold;
  font-size: 2em;;
}