.WinOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.5);
    top: 0;
    left: 0;
}

.WinDialog {
    background-color: rgba(255, 231, 126, 0.7);
    color: darkred;
    box-shadow: 5px 10px 10px gold;
    width: 50%;
    margin: 10% auto;
    padding: 30px;
    text-shadow: 1px 2px white;
    border-radius: 1em;
}