.TopBar {
    display: flex;
    justify-content: space-between;
    background: rgb(190,187,12);
    background: linear-gradient(0deg, rgba(190,187,12,1) 0%, rgba(79,30,0,1) 1%, rgba(142,40,40,1) 37%, rgba(179,43,60,1) 74%, rgba(143,0,9,1) 100%);
}

.TopBar > div {
    padding: 0;
    align-self: center;
    color:sandybrown;
}

.TopBar h1 {
    margin: 0;
}

.TopBar button {
    display: inline-block;
    height: 100%;
    background: transparent;
    color:sandybrown;
    font-weight: 600;
    font-size: 1.2em;
    border: 0;
    padding: 1em 2em;
}

.TopBar button:hover {
    background-color: darkred;
    color: wheat;
    font-weight: 900;
}

.GoToList {
    flex-direction: column;
    position: absolute;

    right: 0;
    margin: 0;
    z-index: 10;
}

.GoToList ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
}

.GoToList ul + ul {
    border-top: 1px solid #666666;
}

.GoToList li {
    margin: 0;
    padding: 10px 20px;
    background-color: wheat;
    color: darkred;
    min-width: 200px;
    cursor: pointer;
    font-weight: 900;
}

.GoToList li:hover {
    background-color: darkred;
    color: wheat;

}
