.Box {
    position: absolute;
    box-sizing: border-box;
    padding: 2px;

}

.InnerBox {
    background-color: darkred;
    width: 100%;
    height: 100%;
    border-radius: 10%;
}

.Landed {
    background-color: red;
}